/************************ CANCEL PROPS ************************/
.no-padding {padding: 0 !important;}

/****************** Fake Link *********************************/
.fake-link {
	text-decoration:underline;
	cursor:pointer;
}

/************************ BUTTONS ************************/
.button {
	display: inline-block;
	overflow: hidden;
	line-height: 27px;
	padding: 0 10px;

	background-clip: padding-box;

	font-weight: bold;
	text-overflow: ellipsis;
	white-space: nowrap;
	margin-bottom: 0;
	vertical-align: middle;

	text-align: center;
	cursor: pointer;

	/*-webkit-user-select: none;*/
	-webkit-box-sizing: border-box;
	-moz-box-sizing :  border-box;
	box-sizing: border-box;


	border-radius: 4px;
}

/* button back */
.button.back {
	position: relative;
	top: 2px;
	display: inline-block;
	/* padding-left: 10px; */
	padding: 0 6px 0 15px;
	overflow: visible;
	font-size: 0.875rem;
	border-left: 0 !important;
	border-radius: 5px 5px 5px 5px !important;
	/*box-shadow: rgba(0, 0, 0, 0.45) 0 1px 2px inset !important;*/
}

.button.back:before {
	content: '';
	position: absolute;
	left: 50%;
	left: 0px;
	width: 0;
	height: 0;
	line-height: 0;
	margin: 0;
	margin-top: 7px;
	padding: 0;
	border-style: dashed;
	border-color: transparent;
	font-size: 6.25rem;
	vertical-align: top;
	border-width: 6px;
	border-right-width: 4px;
	border-right-style: solid;
	border-right-color: white;
}
/* if back is inside table */
.table .button.back {
	top: 0;
	margin: 0 0 0 25px;
}

/************************ BUTTONS COLORS ************************/
/*	white */
.button.white {
	background:#FCFCFC;
	background-image: linear-gradient(to bottom, #FFFFFF, #E5EAEE);
	border: 1px solid #C4C4C4;
	color: #272b34;
	text-shadow: #FFF 0 1px 0;
}

.button.white.active,
.button.grey.active  {
	background: #2567AB;
	background-image: linear-gradient(to bottom, #5F9CC5, #396B9E);
	border: 1px solid #145072;
	color: #FFFFFF;
	text-shadow: #145072 0 -1px 1px;
}


/*	grey */
.button.grey {

	background:#F4F4F4;
	background-image: linear-gradient(to bottom, #F4F4F4, #CCCCCC);
	border: 1px solid #AAAAAA;

	color: #272b34;
	text-shadow: #FFF 0 1px 0;
}

/* blue */
.button.blue {

	background: #2567AB;
	background-image: linear-gradient(to bottom, #5F9CC5, #396B9E);
	color: #FFFFFF;
	text-shadow: #145072 0 -1px 1px;
}

/*	purple */
.button.purple, .button.back {

	background: #3c0073;
	background-image: linear-gradient(rgb(60, 0, 115), rgb(48, 0, 92)) rgb(60, 0, 115);

	border: 1px solid #19294C;
	border-color: #361F5A #361F5A #361F5A;

	color: #FFFFFF;
}

/*	green */
.button.green {

	background:#50ae32;
	background-image: linear-gradient(to bottom, #50ae32, #287110);

	border: 1px solid #086A12;
	border-top-color: #075D10;

	color: #FCFCFC;
	text-shadow: rgba(0, 0, 0, 0.3) 0 -1px 0px;
}



.button.action.confirm {

	background:#50ae32;

	box-shadow: inset 0 1px rgba(255,255,255,0.36);
	text-shadow: 0 -1px rgba(0,0,0,0.36);
	font-weight: 800;

	border: 1px solid #086A12;
	border-top-color: #075D10;

	line-height: 2rem;
	background-image: linear-gradient(to bottom, #50ae32, #287110);

	font-size: 1rem;
	padding: 0 1.25rem;
	border-radius: 6px;

	color: #FCFCFC;
	text-shadow: rgba(0, 0, 0, 0.3) 0 -1px 0px;
}

.button.action.confirm.small {
	line-height: 1.5rem;
	font-size: 0.875rem;
}

.button.action.default.small, .button.action.cancel.small {
	line-height: 1.5rem;
	font-size: 0.875rem;
}


.button.action.cancel {

	color: gray;
	background-color: #EAEAEA;

	line-height: 2rem;
	font-size: 1rem;
	padding: 0 1.25rem;
	border-radius: 6px;
	background-image: -webkit-gradient(linear, left top, left bottom, from(#EAEAEA), to(#CCCCCC));
	box-shadow: inset 0 1px rgba(255,255,255,0.36);
	text-shadow: 1px 1px 0 rgba(75, 75, 75, 0.10);
	font-weight: 800;

	border: 1px solid #BFBFBF;

}

.button.action.default {
	background:#FCFCFC;

	background-color: #EAEAEA;
	background-image: -webkit-gradient(linear, left top, left bottom, from(#EAEAEA), to(#CCCCCC));

	line-height: 2rem;
	font-size: 1rem;
	padding: 0 1.25rem;
	border-radius: 6px;

	border: 1px solid #C4C4C4;
	color: #272b34;
	text-shadow: #FFF 0 1px 0;
}

.button.action.delete, .button.action.refuse {

	background:#EE5F5B;

	line-height: 2rem;
	font-size: 1rem;
	padding: 0 1.25rem;
	border-radius: 6px;

	background-image: -webkit-gradient(linear, left top, left bottom, from(#EE5F5B), to(#BD362F));
	border: 1px solid #BD362F;
	border-color: #BD362F #BD362F #802420;

	color: #FCFCFC;
	text-shadow: rgba(0, 0, 0, 0.3) 0 -1px 0px;
}

.button.action.warning {
	background:#ff6e02;

	line-height: 2rem;
	font-size: 1rem;
	padding: 0 0.5rem;
	border-radius: 6px;

	border: 1px solid #BD362F;
	border-color: #ff6e02 #ff6e02 #ff6e02;

	color: #FCFCFC;
	text-shadow: rgba(0, 0, 0, 0.3) 0 -1px 0px;
}

.button.action.small {
	line-height: 1.4rem;
	font-size: 0.875rem;
	padding: 0 1rem;
}


.button.red {

	background:#EE5F5B;

	background-image: -webkit-gradient(linear, left top, left bottom, from(#EE5F5B), to(#BA2800));
	background-image: -webkit-linear-gradient(to bottom, #EE5F5B, #BA2800);
	background-image: -moz-linear-gradient(to bottom, #EE5F5B, #BA2800);
	background-image: -ms-linear-gradient(to bottom, #EE5F5B, #BA2800);
	background-image: -o-linear-gradient(to bottom, #EE5F5B, #BA2800);
	background-image: linear-gradient(to bottom, #EE5F5B, #BA2800);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorStr='#EE5F5B', EndColorStr='#BA2800');

	line-height: 2rem;
	font-size: 1rem;
	padding: 0 1.25rem;
	border-radius: 6px;

	border: 1px solid #BD362F;
	border-color: #BD362F #BD362F #802420;

	color: #FCFCFC;
	text-shadow: rgba(0, 0, 0, 0.3) 0 -1px 0px;
}

/* disabled */
.button[disabled] {

	background-color: #EEEEEE;
	background-image: none;
	filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);

	color: #9D9DA1;
	border-color: #E0E0E0;
	text-shadow: #FFFFFF 0 1px 0;

	cursor: default;
}

/************************ BUTTONS SIZES ************************/
.button.block {
	display: block;
	width: 100%;
}

.button.medium {
	height:30px;
	line-height: 30px;
	padding: 0px 25px;
}

/************************ BUTTONS ICONS ************************/

.button.icon {
	position: relative;
	padding-left: 30px;
}

.button.icon.block {
	text-align: left;
}

/**************************** LISTS COLORS ****************************/

/* light */
.list.transparent {
	background: none;
	background-color: transparent;
	text-shadow: none;
}

/**************************** MODAL ****************************/
.overlay-background {
	position: absolute;
	z-index: 500;
	top: -25%;
	left: 0;
	width: 100%;
	height: 275%;
	background-color: rgba(0, 0, 0, 0.8);
}

.overlay-background.toplevel {
	z-index: 900;
}

.overlay-background.light  {
		background-color: rgba(0, 0, 0, 0.3);
}


.overlay-content {
	position: fixed;
	z-index: 801;
	top: 25%;
	left: 50%;
	width: 96%;
	margin-left: -48%;
	background-color: rgba(0, 0, 0, 0.8);
}


@media only screen and (min-width: 750px) {
	.overlay-content {
		position: fixed;
		z-index: 801;
		top: 25%;
		left: 50%;
		width: 600px;
		margin-left: -300px;
		background-color: rgba(0, 0, 0, 0.8);
	}
}

.overlay-content.no-background {
	background-color:transparent;
}

.overlay-content.pop.in {
	z-index: 801 !important;
}

.overlay-content.pop.in.lowerlevel {
	z-index: 401 !important;
}

.overlay-content.pop.in.toplevel {
	z-index: 950 !important;
}


.overlay-content .modal-header {
	padding: 8px 8px 8px 8px;
	background-color: #5CA4D3;
	border-bottom: 1px solid #467DA1;
	color: #EAF5F7;
	text-shadow: rgba(0, 0, 0, 0.3) 0 -1px 0;
	border-radius: 5px 5px 0 0;
}

.overlay-content .modal-header.plain {
	background-color: #F7F7F7;
	border: 0;
	color: #000;
	font-weight: bold;
	text-shadow: none;
	text-align: center;
}
.overlay-content .modal-content {
	padding: 7px;
	background: #F7F7F7;
	border-radius: 0 0 5px 5px;
}

.overlay-content .modal-content.no-header {
	border-radius: 5px;
}

.overlay-content .modal-content.plain {
	padding: 7px;
	background: #F7F7F7;
	text-align:center;
	border-radius: 0 0 5px 5px;
}

.overlay-content .modal-close /*, .side-panel-header .modal-close*/ {
	position: absolute;
	top: 3px;
	right: 6px;
	width: 18px;
	height: 18px;
	background-color: transparent;
	color: white;
	border-radius: 0;
}

.overlay-content .modal-close-patched /*, .side-panel-header .modal-close-patched*/ {
	position: absolute;
	z-index:999;
	top: -15px;
	right: 0;
	width: 55px;
	height: 55px;
}

/**************************** FULL OVERLAY ****************************/
.full-overlay-transparent {
	position:fixed;
	top:0;
	left:0;
	height:100%;
	width:100%;
	z-index:450;
}

.full-overlay-transparent.dark {
	background-color:black;
}


/************ Fromt Message ***************/
.front-message-container {
	display: none;
	position: fixed;
	z-index: 800;
	top: 75px;
	left: 50%;
	width: 250px;
	margin-left: -140px;
	padding: 15px 15px;
	background-color: #000000;

	color: #FCFCFC;
	font-size: 1.125rem;
	font-weight: 800;

	-moz-opacity: .85;
	filter: alpha(opacity=85);
	opacity: .85;
	text-align:center;

	border-radius: 5px;
}

.front-message-container.grey {
	color:#000000;
	background: #E0E0E0;
	background-image: -webkit-gradient(linear, left top, left bottom, from(#FFFFFF), to(#E5EAEE));
	background-image: -webkit-linear-gradient(to bottom, #FFFFFF, #E5EAEE);
	background-image: -moz-linear-gradient(to bottom, #FFFFFF, #E5EAEE);
	background-image: -ms-linear-gradient(to bottom, #FFFFFF, #E5EAEE);
	background-image: -o-linear-gradient(to bottom, #FFFFFF, #E5EAEE);
	background-image: linear-gradient(to bottom, #FFFFFF, #E5EAEE);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorStr='#FFFFFF', EndColorStr='#E5EAEE');

	font-size: 1rem;
}

/**************************  Defaults text style ********************/
.text-important {
	font-weight:800;
}

.text-must-read {
	color: #c01717;
	margin-top:2px;
	margin-bottom:2px;
	font-weight:800;
}

.red {
	color: #ff1f1f;
}

/**************************** FONT SIZES ****************************/

.ev-pink-dark, a.ev-pink-dark {
	color: #C7086C;
}

.ev-red, a.ev-red {
	color: #E8595A;
}

/**************************** SPACERS ****************************/
.spacer-small { margin: 5px !important; }
.spacer-top-small { margin-top: 5px !important; }
.spacer-right-small { margin-right: 5px !important; }
.spacer-bottom-small { margin-bottom: 5px !important; }
.spacer-left-small { margin-left: 5px !important; }

.spacer { margin: 10px !important; }
.spacer-top { margin-top: 10px !important; }
.spacer-right { margin-right: 10px !important; }
.spacer-bottom { margin-bottom: 10px !important; }
.spacer-left { margin-left: 10px !important; }

.spacer-medium { margin: 15px !important; }
.spacer-top-medium { margin-top: 15px !important; }
.spacer-right-medium { margin-right: 15px !important; }
.spacer-bottom-medium { margin-bottom: 15px !important; }
.spacer-left-medium { margin-left: 15px !important; }

.spacer-large { margin: 20px !important; }
.spacer-top-large { margin-top: 20px !important; }
.spacer-right-large { margin-right: 20px !important; }
.spacer-bottom-large { margin-bottom: 20px !important; }
.spacer-left-large { margin-left: 20px !important; }

.inner-spacer-small { padding: 5px !important; }
.inner-spacer-top-small { padding-top: 5px !important; }
.inner-spacer-right-small { padding-right: 5px !important; }
.inner-spacer-bottom-small { padding-bottom: 5px !important; }
.inner-spacer-left-small { padding-left: 5px !important; }

.inner-spacer { padding: 10px !important; }
.inner-spacer-top { padding-top: 10px !important; }
.inner-spacer-right { padding-right: 10px !important; }
.inner-spacer-bottom { padding-bottom: 10px !important; }
.inner-spacer-left { padding-left: 10px !important; }

.inner-spacer-medium { padding: 15px !important; }
.inner-spacer-top-medium { padding-top: 15px !important; }
.inner-spacer-right-medium { padding-right: 15px !important; }
.inner-spacer-bottom-medium { padding-bottom: 15px !important; }
.inner-spacer-left-medium { padding-left: 15px !important; }

.inner-spacer-large {padding: 20px !important; }
.inner-spacer-top-large {padding-top: 20px !important; }
.inner-spacer-right-large { padding-right: 20px !important; }
.inner-spacer-bottom-large { padding-bottom: 20px !important; }
.inner-spacer-left-large { padding-left: 20px !important; }


/**************************** MARGINS ****************************/
.margin-auto {margin: auto;}

/**************************** CLEAR ****************************/
.clear-both {clear: both !important;}

/**************************** CURSORS ****************************/
.cursor-pointer{cursor:pointer !important;}

/**************************** TEXT FORMAT ****************************/
.text-uppercase {  text-transform:uppercase; }

/**************************** FONT WEIGHT ****************************/
.weight-600 { font-weight:600; }
.weight-800 { font-weight:800; }

/**************************** TEXT ALIGN ****************************/
.align-left{text-align:left !important;}
.align-right{text-align:right !important;}
.align-center{text-align:center !important;}

/**************************** VERTICAL ALIGN ****************************/
.v-align-top{vertical-align:top !important;}
.v-align-middle{vertical-align:middle !important;}
/* table cell vertical align */
.v-align-top td{vertical-align:top !important;}
.v-align-middle td{vertical-align:middle !important;}

/**************************** DISPLAY ****************************/
.display-none{display:none;}
.block{display:block;}
.inline{display:inline;}
.inline-block {display:inline-block;}


/**************************** POSITION ****************************/
.relative{position:relative;}
.absolute{position:absolute;}
.fixed{position:fixed;}

/**************************** GRID - TABLE MANAGEMENT ****************************/
.table{display:table;width:100%;max-width:100%;height:100%;vertical-align:middle;-moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; }
.table-cell{display:table-cell;width:auto;vertical-align:middle;}
.table-cell.valign-top{vertical-align:top;}
.table-row{display:table-row;}
.table-row-group { display:table-row-group; }
.table-layout-fixed{table-layout:fixed;}
.table-column-group { display:table-column-group; }
.table-caption { display:table-caption; }

.table.layout-fixed {
	table-layout:fixed;
}

.box.form .table-row {
	height:40px;
}
.box.form input {
	height:30px;
}

.table-cell.standard-label {
	width:100px;
}

.table-cell.standard-label-small {
	width:65px;
}

.table-cell.v-align-baseline{vertical-align:baseline;}
.table-cell.v-align-bottom{vertical-align:bottom;}
.table-cell.v-align-top{vertical-align:top;}

.table-cell.xsmall{width: 20px;}
.table-cell.small{width: 40px;}
.table-cell.medium{width: 125px;}
.table-cell.large{width: 250px;}

.table-cell.cell-5{width: 5%;}
.table-cell.cell-10{width: 10%;}
.table-cell.cell-15{width: 15%;}
.table-cell.cell-20{width: 20%;}
.table-cell.cell-25{width: 25%;}
.table-cell.cell-30{width: 30%;}
.table-cell.cell-33{width: 33.333%;}
.table-cell.cell-35{width: 35%;}
.table-cell.cell-40{width: 40%;}
.table-cell.cell-45{width: 45%;}
.table-cell.cell-50{width: 50%;}
.table-cell.cell-55{width: 55%;}
.table-cell.cell-60{width: 60%;}
.table-cell.cell-65{width: 65%;}
.table-cell.cell-70{width: 70%;}
.table-cell.cell-75{width: 75%;}
.table-cell.cell-80{width: 80%;}
.table-cell.cell-85{width: 85%;}
.table-cell.cell-90{width: 90%;}
.table-cell.cell-95{width: 95%;}
.table-cell.cell-100{width: 100%;}

.table-cell.cell-picture{width: 60px; }

/**************************** LOADER ****************************/
.loader {
	display: inline-block;
	width: 40px;
	height: 40px;
	margin: 10px auto;

	background: url(../img/icons/pull-icon@2x.png) 0 -40px no-repeat;
	background-size: 40px 80px;
	-moz-background-size: 40px 80px;
	-webkit-background-size: 40px 80px;

	/* launch loading animation */
	-webkit-animation: loading-animation 2s linear infinite;
	-moz-animation: loading-animation 2s linear infinite;
	-ms-animation: loading-animation 2s linear infinite;
	animation: loading-animation 2s linear infinite;
}

/* sizes */
.loader.small {
	width: 20px;
	height: 20px;

	background: url(../img/icons/pull-icon@2x.png) 0 -20px no-repeat;
	background-size: 20px 40px;
	-moz-background-size: 20px 40px;
	-webkit-background-size: 20px 40px;
}

/**************************** PICTURE FRAME OVERFLOW ****************************/
.picture-frame-overflow-50,
.picture-frame-overflow-inner-50,
.picture-frame-overflow-50 img {
	border-radius: 3px;
}

.picture-frame-overflow-50,
.picture-frame-overflow-50 a,
.picture-frame-overflow-inner-50 {
	width:50px;height:50px;
}
.picture-frame-overflow-50 img {
	clip:rect(0px 50px 50px 0px);
}

.picture-frame-overflow-50,
.picture-frame-overflow-20,
.picture-frame-overflow-120 {
	position: relative;
	display: inline-block;
}

.picture-frame-overflow-inner-50,
.picture-frame-overflow-inner-20,
.picture-frame-overflow-inner-120 {
	position: absolute;
	background-color: transparent;
	border: 1px solid #FFF;
}

.picture-frame-overflow-50 a,
.picture-frame-overflow-20 a,
.picture-frame-overflow-120 a {
	position: relative;
	display: block;
}

.picture-frame-overflow-50 img,
.picture-frame-overflow-20 img,
.picture-frame-overflow-120 img {
	position: absolute;
	top: 1px;
	left: 1px;
}

/**************************** 0o0o0 rock'n roll BUBBLES 0o0o0 ****************************/
/* bubble container */
.bubble {
	position: relative;
	z-index: 8;

	width: 220px;
	min-height: 36px;

	padding: 8px 15px;
	box-sizing:border-box;
	-webkit-box-sizing:border-box;
	border-radius: 15px;
}

.bubble.bubble-100 {
	width:100%;
}

.bubble.big {
	width: 300px;
	margin:5px auto;
}

/* date */
.bubble .date {
	display: block;
	font-size: 0.75rem;
	margin: 5px 0 0 0;
	font-style: italic;
	color: rgba(0,0,0, 0.3);
}

/* arrow container */
.bubble:not(.no-arrow):after {
	content: '';

	position: absolute;
	height: 13px;
	width: 13px;
}

/* arrows position */
.bubble.left {
	float: left;
	margin: 0px 0px 12px 12px;
}

.bubble.left:after {
	left:-6px;
	bottom:20px;

	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
}

.bubble.right {
	float: right;
	margin: 0px 12px 12px 0px;
}

.bubble.right:after {
	right: -6px;
	bottom: 20px;

	-webkit-transform: rotate(225deg);
	-moz-transform: rotate(225deg);
	-o-transform: rotate(225deg);
	transform: rotate(225deg);
	-ms-transform: rotate(225deg);
}

/* bubbles color */
.bubble.white,
.bubble.white:after {
	background: #DDDDDD;
}

.bubble.green,
.bubble.green:after {
	background: #d1fcce;
}

.bubble.blue,
.bubble.blue:after {
	background: #9accef;
}

.bubble.slate,
.bubble.slate:after {
	background: #BECFCD;
}

.bubble.gray,
.bubble.gray:after {
	background: #e6e5e5;
}

.bubble.orange,
.bubble.orange:after {
	background: #FC9E51;
}

.bubble.yellow,
.bubble.yellow:after {
	background: #fcfbad;
}

.bubble.pink,
.bubble.pink:after {
	background: #FB89DF;
}


/*********** DOT SLIDER (EXAMPLE : USED TO SLIDE TICKETS QRCODE ) ************/
.dot-slider-container {
	background-color: #fff;
	display: inline-block;
	padding: 5px 10px;
	border-radius: 20px;
}

.dot-slider-container a.dot {
	margin-right: 5px;
	padding:3px;
	border-radius: 10px;
}

.dot-slider-container .dot {
	width: 10px;
	height: 10px;
	margin-right: 5px;
	border-radius: 5px;
}

.dot-slider-container .dot:last-child {
	margin-right: 0;
}

.dot-slider-container .dot.active {
	background-color: #049BDC;
	box-shadow: rgba(0,0,0,0.7) 1px 1px 5px inset;
}

.dot-slider-container .dot.inactive {
	background-color: #888888;
	box-shadow: rgba(0,0,0,1) 1px 1px 1px;
}

/**************************** FORM MESSAGES ****************************/
.message {
	position: relative;
	display: block;
	clear: both;
	min-height: 20px;
	margin-bottom: 15px;
	padding: 10px 10px 10px 40px;
	font-size: 0.938rem;
	font-weight: normal;
	line-height: 20px;
	border-radius: 5px;
	box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.5) inset
}

.message.info {
	border: 1px solid #3178C0;
	color: #0C4FA3 !important;
	background-color: #6DACEA;
}

.message.error {
	border: 1px solid #DC4E4D;
	color: #B52525 !important;
	background-color: #EC8282;
}

.message.warning {
	border: 1px solid #F9B516;
	color: #C2721B !important;
	background-color: #FBB160;
}

.message.success{
	border: 1px solid #ACCC5D;
	color: #70892B !important;
	background-color: #C8E185;
}

.message.success .icon {
	width: 14px;
	left: 16px;
	background-position: 0 -15px;
}

.message.warning .icon {
	width: 14px;
	left: 16px;
	top: 13px;
	background-position: 0 -45px;
}

.message.error .icon {
	width: 13px;
	left: 16px;
	background-position: 0 0;
}

.message.info .icon {
	width: 6px;
	left: 19px;
	background-position: 0 -30px;
}

#email-confirmation-message {
	font-size:1rem;
}
