/**************************** RESET ****************************/

body, div, span, form, h1, h2, h3, h4, h5, h6, p, ul, li, img, header, section, aside, footer, button {
	margin:0;
	padding:0;
	border:0;

	/* ie scrollbar */
	scrollbar-face-color: #C0C0C0;
	scrollbar-base-color: #E4E4E4;
	scrollbar-arrow-color: #434343;
	scrollbar-track-color: #E4E4E4;
	scrollbar-shadow-color: #E4E4E4;
	scrollbar-highlight-color: #E4E4E4;
	scrollbar-3dlight-color: #E4E4E4;
	scrollbar-darkshadow-Color: #E4E4E4;

	-webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}

html {
	/* Solve the rerender display issue on guest list search */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-smoothing: antialiased;
	-webkit-text-size-adjust: 100%;
}

a {
	border: 0;
	outline: 0;
	color: #197bbd;
	text-decoration: none;
	cursor:pointer;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
	outline: none !important;
}

a:active, a:hover {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
	-webkit-focus-ring-color: rgba(0, 0, 0, 0) !important;
}

a img {
	border: 0;
}

img {
	color: transparent;
	/* For IE. http://css-tricks.com/ie-fix-bicubic-scaling-for-images */
	-ms-interpolation-mode: bicubic;
}

label {
	font-weight: normal;
}

ul {
	list-style-type: none;
}

li {
	position: relative;
}

/**************************** GENERAL ****************************/
body {
	overflow-x: hidden;
	overflow-y: auto;

	font-family: Helvetica, Arial, Verdana, sans-serif;
	font-weight:normal;
	color: #272b34;
	background-color: #2a2a2a;
}

body.withBackground {
	background-size: 1100px auto;
	background-position: 75% 0%;
	background-repeat: no-repeat;

	@media only screen and (min-width: 1101px) {
		background-size: 1920px auto;
	}
}

input, textarea {
	font-family: Helvetica, Arial, Verdana, sans-serif;
}

/**************************** INPUTS ****************************/
.topcoat-checkbox {
	min-height: 1.8rem;
	display: flex;
	align-items: flex-start;
}

input[type="checkbox"]:checked + .topcoat-checkbox__checkmark:after {
	opacity: 1;
}

.topcoat-checkbox__checkmark {
	top: 0.15rem;
	width: 1.44rem;
	height: 1.44rem;
	box-sizing: border-box;
	background-clip: padding-box;
	position: relative;
	display: inline-block;
	vertical-align: top;
	cursor: pointer;
	flex-shrink: 0;
}

.topcoat-checkbox__checkmark:before {
	width: 1.44rem;
	height: 1.44rem;
	background: #e5e9e8;
	border: 1px solid #a5a8a8;
	border-radius: 3px;
	box-shadow: inset 0 1px #fff;
}

.topcoat-checkbox__checkmark:after {
	top: 0.25rem;
	left: 0.1rem;
	opacity: 0;
	width: 1.2rem;
	height: 0.65rem;
	background: transparent;
	border: 5px solid #666;
	border-width: 5px;
	border-top: none;
	border-right: none;
	border-radius: 2px;
	-webkit-transform: rotate(-50deg);
	-ms-transform: rotate(-50deg);
	transform: rotate(-50deg);
}

.topcoat-checkbox__checkmark:after, .topcoat-checkbox__checkmark:before {
	box-sizing: border-box;
	content: '';
	position: absolute;
}

input[type="checkbox"]:focus + .topcoat-checkbox__checkmark:before {
	border: 1px solid #0940fd;
	box-shadow: 0 0 0 2px #6fb5f1;
}

/**************************** TITLES ****************************/
/* header titles */
h1 {
	font-size: 1.625rem;
	color: #FCFCFC;
}

/* section titles */
h3 {
	margin-bottom: 5px;
	font-size: 1.25rem;
	font-weight: bold;
	color: #2E719F;
}

div[data-role="header"] .table {
	height: 41px;
}

div[data-role="header"] a {
	display: inline-block;
	color: #FCFCFC;
}

/**************************** CONTENT ****************************/

div[data-role="content"] .table {padding: 2px;}
div[data-role="content"] .table-cell {padding: 2px;}

div[data-role="content"] .table.no-padding {padding: 0;}
div[data-role="content"] .table-cell.no-padding {padding: 0;}
