/* Transitions from jQtouch (with small modifications): http://www.jqtouch.com/
Built by David Kaneda and maintained by Jonathan Stark.
*/
.in, .out {
	-webkit-animation-timing-function: ease-in-out;
	-webkit-animation-duration: 350ms;
}

.in.fast {
	-webkit-animation-duration: 120ms;
}

.slide.in {
	-webkit-transform: translateX(0);
	-webkit-animation-name: slideinfromright;
}

.slide.out {
	-webkit-transform: translateX(-100%);
	-webkit-animation-name: slideouttoleft;
}

.slide.in.reverse {
	-webkit-transform: translateX(0);
	-webkit-animation-name: slideinfromleft;
}

.slide.out.reverse {
	-webkit-transform: translateX(100%);
	-webkit-animation-name: slideouttoright;
}



/************** SLIDES ***********/
.slide.in.withfade {
	-webkit-transform: translateX(0);
	-webkit-animation-name: slideinfromrightwithfade;
}

.slide.out.withfade {
	-webkit-transform: translateX(-100%);
	-webkit-animation-name: slideouttoleftwithfade;
}

.slide.in.reverse.withfade {
	-webkit-transform: translateX(0);
	-webkit-animation-name: slideinfromleftwithfade;
}

.slide.out.reverse.withfade {
	-webkit-transform: translateX(100%);
	-webkit-animation-name: slideouttorightwithfade;
}

.fade.out {
	opacity: 0;
	-webkit-animation-duration: 125ms;
	-webkit-animation-name: fadeout;
	-moz-animation-duration: 125ms;
	-moz-animation-name: fadeout;
	-webkit-backface-visibility: hidden;
}

.fade.in {
	opacity: 1;
	-webkit-animation-duration: 225ms;
	-webkit-animation-name: fadein;
	-moz-animation-duration: 225ms;
	-moz-animation-name: fadein;
	-webkit-backface-visibility: hidden;
}

.pop {
	-webkit-transform-origin: 50% 50%;
}

.pop.in {
	-webkit-transform: scale(1);
	opacity: 1;
	-webkit-animation-name: popin;
}

.pop.out.reverse {
	-webkit-transform: scale(.2);
	opacity: 0;
	-webkit-animation-name: popout;
}

.pop.in.reverse {
	-webkit-animation-name: dontmove; 
}

@-webkit-keyframes popin {
	from {
		-webkit-transform: scale(.2);
		opacity: 0;
	}
	to {
		-webkit-transform: scale(1);
		opacity: 1;
	}
}

@-webkit-keyframes popout {
	from {
		-webkit-transform: scale(1);
		opacity: 1;
	}
	to {
		-webkit-transform: scale(.2);
		opacity: 0;
	}
}

/********************************* keyframes **************************************/

/* fade */

@-webkit-keyframes fade {
	from { }
	to { opacity: 0.3; }
}
@keyframes fade {
	from { }
	to { opacity: 0.3; }
}

